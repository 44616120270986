.progress-bar {
  width: 100%;
}

.title {
  font-size: 12px;
  margin-bottom: 5px;
  color: #A2A2A2
  
}

.bar {
  width: 100%;
  height: 2px;
  background-color: #D9D9D9;
}

.fill {
  height: 2px;
  background-color: #000;
}