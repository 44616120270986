/* src/components/CanvasList.css */
.canvas-item {
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.canvas-image {
  width: 100%;
  height: auto;
  object-fit: cover;

}

.canvas-title {
  margin-top: 20px;
}