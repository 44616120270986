/* Load custom fonts */

@font-face {
  font-family: 'ArchivoNarrow-VariableFont_wght';
  src: url('../fonts/ArchivoNarrow-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Tienne-Regular';
  src: url('../fonts/Tienne-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Spider-Man';
  src: url('../fonts/Spider-Man.otf') format('truetype');
}

@font-face {
  font-family: 'Plakette-Bold';
  src: url('../fonts/Plakette-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'Knockout_HTF47Bantamweight_Regular';
  src: url('../fonts/Knockout HTF47Bantamweight Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Oswald';
  src: url('../fonts/Oswald-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Slussen Mono';
  src: url('../fonts/Slussen-Mono-Regular.otf') format('opentype');
}


Oswald-VariableFont_wght




.canvas-section {
  width: 100%;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgb(255, 255, 255, 0);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: rgba(242, 242, 242, 0) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 1px 4px 0px;
  box-sizing: border-box;
  color: rgb(17, 17, 17);
  display: block;
  font-family: 'Space Grotesk';
  font-size: 14px;
  line-height: 22.4px;
  list-style-type: none;
  margin-bottom: 36px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 16px !important;
  padding-bottom: 18px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 18px;
  position: relative;
  text-size-adjust: 100%;
  unicode-bidi: isolate;
  width: 540px;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;


}


.canvas-container {
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  box-shadow: 10px 10px 70px 10px rgba(0, 0, 0, 0.25);
}


.image-container {
    display: flex;
    justify-content: center;
  }
  
  .profile-image {
    width: 50px;
    height: 50px;
    /* margin-top: 60px; */
  }
  
  .artist-name {
    color: #111;
    font-size: 14px;
    font-family: 'Space Grotesk';
    margin-top: 10px;
    text-align: left;
    font-weight: 400;
    display: block;
  }

  .artist-description {
    color:  #7B7B7B;
    font-size: 14px;
    font-family: 'Space Grotesk';
    margin-top: 0px;
    text-align: left;
    font-weight: 400;
    display: block;
    height: 22.3984px;
    line-height: 22.4px;
  }

  .artist-instagram-link {
    box-sizing: border-box;
    color: rgb(153, 153, 153);
    display: block;
    font-family: Graphik, sans-serif;
    font-size: 14px;
    height: 24px;
    line-height: 22.4px;
    list-style-type: none;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    text-overflow: ellipsis;
    text-size-adjust: 100%;
    text-wrap: nowrap;
    unicode-bidi: isolate;
    white-space-collapse: collapse;
    /* width: 180px; */
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    
  }

  .link-badge{
    align-items: center;
    background-color: rgb(246, 246, 246);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-sizing: border-box;
    color: rgb(85, 85, 85);
    cursor: pointer;
    display: inline-flex; /* Changed from 'flex' to 'inline-flex' */
    flex-shrink: 1;
    font-family: Graphik, sans-serif;
    font-size: 12px;
    /* height: 24px; */
    line-height: 19.2px;
    list-style-type: none;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    text-decoration-color: rgb(85, 85, 85);
    text-decoration-line: none;
    text-decoration-style: solid;
    text-decoration-thickness: auto;
    text-overflow: ellipsis;
    text-size-adjust: 100%;
    text-wrap: nowrap;
    white-space-collapse: collapse;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

}

.icon {
  transition: opacity 0.3s ease;
}

.btn-istyping{
  background-color: #ecf0f1 !important;

}


.link-badge-saved {
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

  
  .artist-note {
    color: rgb(85, 85, 85);
    font-size: 14px;
    font-family: 'Space Grotesk';
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
    height: 22.3984px;
    line-height: 22.4px;
  }


  .form-input{
    margin-top:30px !important;
    .personalisation-input {
  background-color: white;
  border: 2px solid black;
  color: #272727;
  font-family: 'Space Grotesk';
  font-size: 15px;
}
  }
  
  .form-control:focus {
    color: black;
    background-color: var(--bs-body-bg);
    border-color: black !important;
    outline: 0;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.35) !important; 

  }

  

  .choose-form-button {
    width: 100%;
  }

  .description-box{
    background-color: rgb(255, 255, 255);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    box-shadow: rgba(242, 242, 242, 1) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 1px 4px 0px;
    box-sizing: border-box;
    color: rgb(17, 17, 17);
    display: block;
    font-family: 'Space Grotesk';
    font-size: 14px;
    line-height: 22.4px;
    list-style-type: none;
    margin-bottom: 36px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 16px !important;
    padding-bottom: 18px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 18px;
    position: relative;
    text-size-adjust: 100%;
    unicode-bidi: isolate;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  }

  .description-text{
    box-sizing: border-box;
    color:  #555;
    display: block;
    font-family: space-grotesk, sans-serif;
    font-size:14px;
    height: 22.3984px;
    line-height: 22.4px;
  }

  .description-header{
    color: rgb(153, 153, 153);
    font-size: 12px;
    margin-bottom: 10px;
  }

  .your-class {
    margin-top: 40px;
  }

  .choose-form-button {
  visibility: hidden;
}

.show-button {
  visibility: visible;
  background-color: black;
  box-shadow: rgba(0, 0, 0, 0.65) 0px 3px 10px 0px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  font-family: 'Space Grotesk' !important;
  font-size:14px;
  font-weight:400;
  position: fixed;
  bottom: 40px;
  z-index: 1000;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  width: auto !important;
  display: inline-flex;
  
}

.image{
  width: 100%;
}

.button{
  margin-top:5px;
  margin-bottom:5px;

}

.custom-carousel .carousel-indicators li {
  background-color: black;
}

.custom-carousel .carousel-indicators .active {
  background-color: black;
}

.carousel-control-next-icon{
  background-color: black;
  color: black;
}

.carousel-control-prev-icon{
  background-color: black;
  color: black;
}

.btn-download{
  margin: 5px;
  background-color: #fff!important;
  color: #111!important;
  Font-size: 14px !important;
  border: 2px solid #000!important;
  font-family: 'Space Grotesk' !important;
  border-radius: 50px !important;

}

.btn-download:hover{
  margin: 5px;
  background-color: #fff!important;
  color: #111!important;
  border: 2px solid #000!important;
  Font-size: 14px !important;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.35) !important; 
  border-radius: 50px;


}

.create-card {
  background-color: #fff !important;
  height: 400px !important;
  border: 2px solid #000 !important;
  border-radius: 20px;
  transition: box-shadow 0.2s ease !important;
}

.create-card:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.product-container {
  cursor: pointer;
  padding: 10px;
  border-radius: 24px;
  margin: 24px;
}

.product-container {
  cursor: pointer;
  padding: 10px;
  border-radius: 24px;
  margin: 24px;
}

.product-container:hover {
  background-color: #f5f5f5; /* Change this to the color you want on hover */
}

.product-container-selected {
  background-color: rgb(250, 250, 250);
  border: 1px solid rgba(0, 0, 0, 0.16);

}

.product-container-not-selected {
  background-color: transparent;
}

body.full-page-background {
  background-color: #FAFAFA;
}

.canvas-control {
  color: #333; /* Change text color */
  font-size: 12px; /* Change font size */
  border: 2px solid black; /* Add 2px black border */
  background-color: white;
  border: 2px solid black;
  color: #111;
  font-size: 14px;
  font-family: 'Space Grotesk';
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom:5px;
  border-radius: 20px;
  padding-top: 5px;
  display: inline-block; 
  margin-right: 5px;
  transition: all 0.15s ease-in-out; /* Add this line */

}

.canvas-control:hover {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.35) !important;
}

input[type="color"] {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: pointer;
  margin-left: 5px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

.round-button {
  border-radius: 50%;
}

.accordion-header {
  font-size: 20px;
  font-family: 'Space Grotesk';
  box-shadow: rgba(242, 242, 242, 1) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 1px 4px 0px;
  color: rgb(17, 17, 17);
  padding-left: 24px;
  padding-right: 24px;
  border: none;
}

.accordion-body {
  font-size: 14px;
  font-family: 'Space Grotesk';
  box-shadow: rgba(242, 242, 242, 1) 0px 0px 0px 1px, #00000000 0px 1px 4px 0px;
  color: rgb(17, 17, 17);
  padding-left: 24px;
  padding-right: 24px;
  border: none;
}

.accordion-header button {
  font-size: 14px;
  padding:10px; 
}

select.form-select {

  padding-right: 32px;
  box-shadow: rgba(242, 242, 242, 1) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 1px 4px 0px;
  color: rgb(17, 17, 17);
  padding-left: 32px;
  padding-top: 16px;
  padding-bottom:16px;
  border: none;
  font-size: 14px;
  font-family: 'Space Grotesk';
}

.grey-circle {
  background-color: grey;
  border-radius: 50%;
}

.grey-circle {
  width: 60px;
  height: 60px;
  background-color: #fafafa;
  border-radius: 50%;
}

.artist-name-placeholder {
  width: 200px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  background-color: #fafafa;
  margin:1px;
}

.artist-description-placeholder{
  width: 200px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  background-color: #fafafa;
  margin:1px;
}

.artist-note-placeholder{
  width: 100%; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  background-color: #fafafa;
  margin:1px;
}

.bg {
  aspect-ratio: 16/9;
  animation: skeleton-loading .6s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: #fafafa;
  }
  100% {
    background-color: #F0F0F0;
  }
}

.bio-link {
  background-color: #fff;
  height: 24px;
  color: #7B7B7B;
  padding: 0 10px;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  text-decoration: none;
  border-radius: 12px;
  transition: box-shadow 0.3s ease;
  border: 1px solid rgb(242, 242, 242)
}

.bio-link:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(80, 80, 80);
  color: #7B7B7B;
  
}

.popover-style {
  background-color: #111 !important;
}

.share-popover {
  width: 600px !important;
}

.popover-body-style {
  color: #fff !important;
  padding: 0.5rem !important; /* Adjust as needed */
  font-family: 'Space Grotesk';
  font-size:10px;

}